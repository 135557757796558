<script setup lang="ts"></script>

<template>
  <div>
    <NuxtLink external href="tel:800911911">
      <button
        class="flex items-center gap-2 rounded-full bg-green-500 px-4 py-1 text-sm font-medium text-white"
      >
        <Icon name="fa6-solid:phone" />
        800 911 911
      </button>
    </NuxtLink>
  </div>
</template>
